


































import { Component, Vue, Ref } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import { RouterNames } from "@/router/routernames";
import entrevistaModule from "@/store/modules/entrevista-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { EventEditButton } from "@/components/DatatableGenerico/data-table-events";
@Component({
  components: {
    PacienteFichaOpciones: () => import("./paciente-ficha-opciones.vue"),
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue")
  }
})
export default class pacienteConsultas extends Vue {
  @Ref("DataTable") DataTable!: any;
  public id_paciente!: number;
  public search: string = "";

  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
    }
    entrevistaModule.getentrevistas(this.id_paciente ? this.id_paciente : 0);
  }

  public color_data(e: any, tipo_dato: number) {
    if (e.data.row.rowIndex > 0) {
      let entrevista = this.entrevistas[e.data.row.rowIndex - 1];
      let dato_ahora = Number.parseFloat(e.data.value);
      let dato_antes = 0;
      switch (tipo_dato) {
        case 1:
          dato_antes = entrevista.peso;
          break;
        case 2:
          dato_antes = entrevista.calorias_dieta;
          break;
      }

      if (dato_ahora > dato_antes) {
        return "color:red;";
      }
      if (dato_ahora === e.data.value) {
        return "color:black;";
      }
      if (dato_ahora < dato_antes) {
        return "color:green;";
      }
    }
    return "color:black;";
  }

  public get entrevistas() {
    return entrevistaModule.entrevistas;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "fecha_entrevista",
        "Fecha entrevista",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );

    Columnas.push(
      new ClassColumnDataTable(
        "peso",
        "Peso",
        datatypes.number,
        true,
        datatypes.maxlengthinteger
      ).CreateTemplate("cell-template-1")
    );

    Columnas.push(
      new ClassColumnDataTable(
        "alimentacion",
        "Alimentación",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );

    Columnas.push(
      new ClassColumnDataTable(
        "objetivo",
        "Objetivo",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );

    Columnas.push(
      new ClassColumnDataTable(
        "calorias_dieta",
        "Kcal",
        datatypes.string,
        true,
        datatypes.maxlength
      ).CreateTemplate("cell-template-2")
    );
    return Columnas;
  }
  public editarEntrevista(id_entrevista: number) {
    this.$router.push({
      name: RouterNames.entrevista,
      query: {
        id_paciente: this.$route.query.id,
        id_entrevista: UtilsEncript.Encriptar(id_entrevista.toString())
      }
    });
  }

  public imprimirEntrevista(id_entrevista: number) {
    entrevistaModule.descargarconsultaPDF(id_entrevista);
  }
  public NuevaConsulta() {
    this.$router.push({
      name: RouterNames.entrevista,
      query: {
        id_paciente: this.$route.query.id
      }
    });
  }
}
